import axiosClient from "@/services/api/index";
import { AxiosResponse } from "axios";
import {
  CreateInitialHoldWithPartnerInterface,
  CreateInitialHoldInterface,
} from "@/types/InitialHoldInterface";

export default {
  getInitialHolds(params = {}): Promise<AxiosResponse> {
    return axiosClient.get("initial-hold", { params });
  },
  createInitialHold(
    data: CreateInitialHoldInterface | CreateInitialHoldWithPartnerInterface
  ): Promise<AxiosResponse> {
    return axiosClient.post("initial-hold", data);
  },
  createInitialHoldFromLandownerSide(
    data: CreateInitialHoldInterface | CreateInitialHoldWithPartnerInterface
  ): Promise<AxiosResponse> {
    return axiosClient.post("initial-hold/landowner-create-approved", data);
  },
  getInitialHoldById(id: string, estateId: string): Promise<AxiosResponse> {
    return axiosClient.get(`initial-hold/${id}?estateId=${estateId}`);
  },
  approveInitialHold({
    initialHoldId,
    expirationDate,
  }: {
    initialHoldId: string;
    expirationDate: number;
  }): Promise<AxiosResponse> {
    return axiosClient.patch(`initial-hold/approve/${initialHoldId}`, {
      expirationDate,
    });
  },
  declineInitialHold({
    id,
    reasonForDecline,
  }: {
    id: string;
    reasonForDecline: string | null;
  }): Promise<AxiosResponse> {
    return axiosClient.patch(`initial-hold/decline/${id}`, {
      reasonForDecline,
    });
  },
  cancelInitialHold({
    id,
    reasonForCancel,
  }: {
    id: string;
    reasonForCancel: string;
  }): Promise<AxiosResponse> {
    return axiosClient.patch(`initial-hold/cancel/${id}`, { reasonForCancel });
  },
  getPendingInitialHoldsCount(id: string): Promise<AxiosResponse> {
    return axiosClient.get(`initial-hold/pending-count/${id}`);
  },
  getApprovedInitialHoldsByLotsIds(params = {}): Promise<AxiosResponse> {
    return axiosClient.get(`initial-hold/lots/ids`, { params });
  },
  extendInitialHoldExpirationDate({
    id,
    daysToExtend,
  }: {
    id: string;
    daysToExtend: number;
  }): Promise<AxiosResponse> {
    return axiosClient.patch(`initial-hold/extend/${id}`, {
      daysToExtend,
    });
  },
  setReasonForTermination({
    id,
    reasonForTermination,
  }: {
    id: string;
    reasonForTermination: string;
  }): Promise<AxiosResponse> {
    return axiosClient.patch(`initial-hold/set-reason-for-termination/${id}`, {
      reasonForTermination,
    });
  },
  getPendingInitialHoldsCountByLotId(lotId: string): Promise<AxiosResponse> {
    return axiosClient.get(`initial-hold/pending-count-by-lot/${lotId}`);
  },
};
