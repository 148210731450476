import { Business } from "@/types/BusinessInterface";
import { LotInterface } from "@/types/LotInterface";

export function getBusinessColorFromLot(lot: LotInterface, businesses: Business[]): string {
  const defaultColor = "#8f00ff";
  const matchedBusiness = businesses.find((business) =>
    business.landownerId && lot.ownershipId &&
    business.landownerId === lot.ownershipId
  );

  return matchedBusiness?.branding?.color ?? defaultColor;
}
