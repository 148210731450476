import { AxiosResponse } from "axios";

export const getEstateByIdAdapter = async (res: AxiosResponse) => {
  const { businessId, collaboratorBusinessIds, collaboratorIds, ...rest } =
    res.data;

  return {
    ...res,
    data: {
      ...rest,
      business: businessId,
      collaboratorBusiness: collaboratorBusinessIds,
      collaborators: collaboratorIds,
    },
  };
};
