import { RouteRecordRaw } from "vue-router";
import EmptyLayout from "../layouts/EmptyLayout.vue";
import EmptyLayoutWithHeader from "../layouts/EmptyLayoutWithHeader.vue";
import CustomerLayoutWithNav from "../layouts/CustomerLayoutWithNav.vue";
import BuilderLayout from "@/layouts/BuilderLayout.vue";
import { UserRoles } from "@/enums/UserRoles";
import { adminRoutes } from "@/router/adminRoutes";
import store from "@/store";
import { ROUTER_SCROLL_BEHAVIORS } from "@/constants/scrollBehaviors";
import { PlansFormEnum } from "@/enums/PlansFormEnum";
const salesPersonProfile = {
  component: () =>
    import(
      /* webpackChunkName: "landowner-profile" */ "@/views/profile/sales-person/SalesPersonCreateProfile.vue"
    ),
  meta: {
    access: [UserRoles.LANDOWNER],
  },
};
const routes: Array<any> = [
  ...adminRoutes,
  {
    path: "/builders",
    name: "LandingBuilders",
    component: () =>
      import(/* webpackChunkName: "LandingBuilders" */ "../views/Landing.vue"),
    meta: {
      layout: EmptyLayout,
      isLogin: true,
      scrollBehavior: ROUTER_SCROLL_BEHAVIORS.INSTANT,
    },
  },
  {
    path: "/plans",
    name: "LandingPlans",
    component: () =>
      import(
        /* webpackChunkName: "LandingPlans" */ "../views/LandingPlans.vue"
      ),
    meta: {
      layout: EmptyLayout,
      isLogin: true,
      scrollBehavior: ROUTER_SCROLL_BEHAVIORS.INSTANT,
    },
  },
  {
    path: "/plans/waitlist",
    name: "WaitlistForm",
    component: () =>
      import(
        /* webpackChunkName: "LandingFormView" */ "../views/LandingFormView.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
    props: { type: PlansFormEnum.WAITLIST },
  },
  {
    path: "/plans/sign-up",
    name: "SignUpForm",
    component: () =>
      import(
        /* webpackChunkName: "LandingFormView" */ "../views/LandingFormView.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
    props: { type: PlansFormEnum.SIGN_UP },
  },
  {
    path: "/",
    name: "Landing",
    component: () =>
      import(/* webpackChunkName: "landing" */ "../views/Landing.vue"),
    meta: {
      layout: EmptyLayout,
      isLogin: true,
    },
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () =>
      import(
        /* webpackChunkName: "maintenance" */ "../views/MaintenanceView.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/terms-and-conditions",
    name: "Terms",
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/Terms.vue"),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/docusign",
    name: "Docusign",
    component: () =>
      import(/* webpackChunkName: "Docusing" */ "../views/Docusign.vue"),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/rei",
    name: "Rei",
    component: () => import(/* webpackChunkName: "Rei" */ "../views/Rei.vue"),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/terms-of-service",
    name: "CustomerTerms",
    component: () =>
      import(
        /* webpackChunkName: "customer-terms" */ "../views/CustomerTerms.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ "../views/PrivacyPolicy.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "DashboardView" */ "../views/DashboardView.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/documents/:id*",
    name: "Documents",
    meta: {
      module: "documents",
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
    component: () =>
      import(
        /* webpackChunkName: "DocumentsView" */ "../views/DocumentsView.vue"
      ),
  },
  {
    path: "/eoi",
    name: "EOI",
    component: () =>
      import(/* webpackChunkName: "EOIView" */ "../views/EOIView.vue"),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/eoi/:id",
    name: "EOIDetails",
    component: () =>
      import(
        /* webpackChunkName: "EOIDetails" */ "../components/eoi/EOIDetails.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/eoi/edit-form",
    name: "EOIEditForm",
    component: () =>
      import(/* webpackChunkName: "EOIEditForm" */ "../views/EOIEditForm.vue"),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/waitlist",
    name: "Waitlist",
    component: () =>
      import(/* webpackChunkName: "EOIDetails" */ "../views/WaitlistView.vue"),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () =>
      import(
        /* webpackChunkName: "contacts" */ "@/views/contacts/ContactsView.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/contacts/:id",
    name: "ContactDetails",
    component: () =>
      import(
        /* webpackChunkName: "contactsDetails" */ "@/views/contacts/ContactsDetailsView.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  // {
  //   path: "/reports",
  //   name: "Reports",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "reports" */ "@/views/reportsLandowner/ReportsView.vue"
  //     ),
  //   meta: {
  //     access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
  //   },
  // },
  {
    path: "/reports",
    name: "Reports",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reportsLandowner/ReportsView.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/lots",
    name: "Stages",
    component: () =>
      import(/* webpackChunkName: "lots" */ "@/views/Stages.vue"),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/lots/stage/:stageId",
    name: "Lots",
    component: () => import(/* webpackChunkName: "lots" */ "@/views/Lots.vue"),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/lots/stage/:stageId/lot/:lotId",
    name: "Lot",
    component: () =>
      import(/* webpackChunkName: "lots" */ "@/views/lot/Lot.vue"),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
    children: [
      {
        name: "DefaultLotActions",
        path: "",
        redirect: { name: "LotActions" },
      },
      {
        path: "actions",
        name: "LotActions",
        component: () =>
          import(/* webpackChunkName: "lots" */ "@/views/lot/LotActions.vue"),
        meta: {
          access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
        },
      },
      {
        path: "contract information forms",
        name: "LotEoisRequests",
        component: () =>
          import(
            /* webpackChunkName: "lots" */ "@/views/lot/LotEoisRequests.vue"
          ),
        meta: {
          access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
        },
      },
      {
        path: "documents",
        name: "LotDocuments",
        component: () =>
          import(/* webpackChunkName: "lots" */ "@/views/lot/LotDocuments.vue"),
        meta: {
          access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
        },
      },
      {
        path: "contracts",
        name: "LotContracts",
        component: () =>
          import(/* webpackChunkName: "lots" */ "@/views/lot/LotDocuments.vue"),
        meta: {
          access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
        },
      },
      {
        path: "conditions",
        name: "LotConditions",
        component: () =>
          import(
            /* webpackChunkName: "lots" */ "@/views/lot/LotConditions.vue"
          ),
        meta: {
          access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
        },
      },
      {
        path: "options",
        name: "LotOptions",
        component: () =>
          import(/* webpackChunkName: "lots" */ "@/views/lot/LotOptions.vue"),
        meta: {
          access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
        },
      },
      {
        path: "notes",
        name: "LotNotes",
        component: () =>
          import(/* webpackChunkName: "lots" */ "@/views/lot/LotNotes.vue"),
        meta: {
          access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
        },
      },
      {
        path: "hold requests",
        name: "LotInitialHoldRequests",
        component: () =>
          import(
            /* webpackChunkName: "lots" */ "@/views/lot/LotInitialHold.vue"
          ),
        meta: {
          access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
        },
      },
    ],
  },
  {
    path: "/landowner/profile/general",
    name: "LandownerProfile",
    component: () =>
      import(
        /* webpackChunkName: "landowner-profile" */ "@/views/profile/landowner/LandownerInfo.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/landowner/profile/general/update",
    name: "LandownerProfileUpdate",
    component: () =>
      import(
        /* webpackChunkName: "landowner-profile" */ "@/views/profile/landowner/LandownerProfileUpdate.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/landowner/profile/general/change-password",
    name: "LandownerProfileChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "landowner-profile" */ "@/views/profile/landowner/LandownerChangePassword.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/landowner/profile/estates",
    name: "LandownerProfileEstates",
    component: () =>
      import(
        /* webpackChunkName: "landowner-profile" */ "@/views/profile/landowner/LandownerEstates.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/landowner/profile/users/create",
    name: "LandownerUsersCreate",
    component: salesPersonProfile.component,
    meta: salesPersonProfile.meta,
  },
  {
    path: "/landowner/profile/users/:salesPersonId/edit",
    name: "LandownerUsersEdit",
    component: salesPersonProfile.component,
    meta: salesPersonProfile.meta,
  },
  {
    path: "/landowner/profile/users",
    name: "LandownerUsers",
    component: () =>
      import(
        /* webpackChunkName: "landowner-profile" */ "@/views/profile/landowner/SalesPersonList.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER],
    },
  },
  {
    path: "/landowner/profile/subscriptions-billing",
    name: "LandownerSubscription",
    component: () =>
      import(
        /* webpackChunkName: "landowner-subscription" */ "@/views/profile/landowner/Subscription.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER],
    },
  },
  {
    path: "/landowner/profile/estates/:estateId/update",
    name: "LandownerProfileEstateUpdate",
    component: () =>
      import(
        /* webpackChunkName: "landowner-profile" */ "@/views/profile/landowner/LandownerEstateUpdate.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/leads",
    name: "Leads",
    component: () =>
      import(/* webpackChunkName: "leads" */ "@/views/lead/Leads.vue"),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/listing",
    name: "Listing",
    component: () =>
      import(
        /* webpackChunkName: "listing" */ "../views/listing/ListingsView.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER],
    },
  },
  {
    path: "/listing/create",
    name: "ListingCreate",
    component: () =>
      import(
        /* webpackChunkName: "listing" */ "@/views/listing/CreateListing.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER],
    },
  },
  {
    path: "/listing/edit/:listingId",
    name: "ListingEdit",
    component: () =>
      import(
        /* webpackChunkName: "listing" */ "@/views/listing/EditListing.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER],
    },
  },
  {
    path: "/leads/:id",
    name: "LeadDetails",
    component: () =>
      import(/* webpackChunkName: "leads" */ "@/views/lead/LeadDetails.vue"),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/leads/:id/update",
    name: "LeadUpdate",
    component: () =>
      import(/* webpackChunkName: "leads" */ "@/views/lead/LeadUpdate.vue"),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/estate/:estateId",
    name: "EstateMap",
    component: () =>
      import(/* webpackChunkName: "EstateMap" */ "@/views/EstateMapView.vue"),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/estate/:estateId/lot/:lotId/eoi-request/:businessId?",
    name: "EOIRequest",
    component: () =>
      import(
        /* webpackChunkName: "EstateEOIRequest" */ "../views/customer/EOIRequest.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/estate/:estateId/lot/:lotId/eoi/:eoiId/eoi-request-payment",
    name: "EOIRequestPayment",
    component: () =>
      import(
        /* webpackChunkName: "EstateEOIRequest" */ "../views/customer/EOIRequestPayment.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/estate/:estateId/eoi-request-payment-confirmation",
    name: "EOIRequestPaymentConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "EstateEOIRequest" */ "../views/customer/EOIRequestPaymentConfirmation.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/estate/:estateId/waitlist",
    name: "EOIWaitlist",
    component: () =>
      import(
        /* webpackChunkName: "EstateEOIRequest" */ "../views/customer/EOIWaitlist.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/estate/:estateId/waitlist-confirmation",
    name: "EOIWaitlistConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "EOIWaitlistConfirmation" */ "../views/customer/EOIWaitlistConfirmation.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/estate/:estateId/lot/:lotId/draft-confirmation",
    name: "EOIDraftConfirmation",
    component: () =>
      import(
        /* webpackChunkName: "EstateEOIRequest" */ "../views/customer/EOIDraftConfirmation.vue"
      ),
    meta: {
      layout: EmptyLayout,
      access: [UserRoles.CUSTOMER],
    },
  },
  {
    path: "/initial-hold",
    name: "InitialHold",
    component: () =>
      import(
        /* webpackChunkName: "HoldRequests" */ "../views/initialHold/initialHoldView.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/initial-hold/:id",
    name: "HoldRequestDetails",
    component: () =>
      import(
        /* webpackChunkName: "HoldRequests" */ "../views/initialHold/InitialHoldDetails.vue"
      ),
    meta: {
      access: [UserRoles.LANDOWNER, UserRoles.SALES_PERSON],
    },
  },
  {
    path: "/customer",
    name: "Customer",
    redirect: { name: "CustomerLots" },
    component: () =>
      import(
        /* webpackChunkName: "CustomerView" */ "@/views/customer/CustomerView.vue"
      ),
    meta: {
      access: [UserRoles.CUSTOMER],
    },
  },
  {
    path: "/customer/lots",
    name: "CustomerLots",
    component: () =>
      import(
        /* webpackChunkName: "CustomerLots" */ "@/views/customer/CustomerLotsView.vue"
      ),
    meta: {
      layout: CustomerLayoutWithNav,
      access: [UserRoles.CUSTOMER],
    },
  },
  {
    path: "/customer/lots/:id/eoi/:eoiId",
    name: "CustomerLotDetails",
    redirect: { name: "CustomerLotActivity" },
    component: () =>
      import(
        /* webpackChunkName: "CustomerLotDetails" */ "@/views/customer/CustomerLotDetailsView.vue"
      ),
    meta: {
      layout: CustomerLayoutWithNav,
      access: [UserRoles.CUSTOMER],
      customScroll: true,
    },
    children: [
      {
        path: "activity",
        name: "CustomerLotActivity",
        component: () =>
          import(
            /* webpackChunkName: "CustomerLotActivity" */ "@/components/customer/lotDetails/CustomerLotActivity.vue"
          ),
      },
      {
        path: "documents",
        name: "CustomerLotDocuments",
        component: () =>
          import(
            /* webpackChunkName: "CustomerLotDocuments" */ "@/components/customer/lotDetails/CustomerLotDocuments.vue"
          ),
      },
      {
        path: "eoi-details",
        name: "CustomerLotEoiDetails",
        component: () =>
          import(
            /* webpackChunkName: "CustomerLotEoiDetails" */ "@/components/customer/lotDetails/CustomerLotEoiDetails.vue"
          ),
      },
      {
        path: "contracts",
        name: "CustomerLotContracts",
        component: () =>
          import(
            /* webpackChunkName: "CustomerLotContracts" */ "@/components/customer/lotDetails/CustomerLotContracts.vue"
          ),
      },
      {
        path: "estate-contacts",
        name: "CustomerLotEstateContacts",
        component: () =>
          import(
            /* webpackChunkName: "CustomerLotEstateContacts" */ "@/components/customer/lotDetails/CustomerLotEstateContacts.vue"
          ),
      },
    ],
  },
  // Access limited
  // {
  //   path: "/customer/documents",
  //   name: "CustomerDocuments",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "CustomerDocuments" */ "@/views/customer/CustomerDocumentsView.vue"
  //     ),
  //   meta: {
  //     layout: CustomerLayoutWithNav,
  //     access: [UserRoles.CUSTOMER],
  //   },
  // },
  {
    path: "/customer/waitlist",
    name: "CustomerWaitlist",
    component: () =>
      import(
        /* webpackChunkName: "CustomerWaitlist" */ "@/views/customer/CustomerWaitListView.vue"
      ),
    meta: {
      layout: CustomerLayoutWithNav,
      access: [UserRoles.CUSTOMER],
    },
  },
  {
    path: "/customer/waitlist/:id",
    name: "CustomerWaitlistDetailsView",
    component: () =>
      import(
        /* webpackChunkName: "CustomerWaitlistDetailsView" */ "@/views/customer/CustomerWaitlistDetailsView.vue"
      ),
    meta: {
      layout: CustomerLayoutWithNav,
    },
  },
  {
    path: "/customer/waitlist/:id/edit",
    name: "CustomerWaitlistDetailsEditView",
    component: () =>
      import(
        /* webpackChunkName: "CustomerWaitlistDetailsEditView" */ "@/views/customer/CustomerWaitlistDetailsEditView.vue"
      ),
    meta: {
      layout: CustomerLayoutWithNav,
    },
  },
  {
    path: "/customer/profile",
    name: "CustomerProfile",
    component: () =>
      import(
        /* webpackChunkName: "CustomerProfile" */ "@/views/customer/CustomerProfileView.vue"
      ),
    meta: {
      layout: CustomerLayoutWithNav,
      access: [UserRoles.CUSTOMER],
    },
  },
  {
    path: "/customer/profile/edit",
    name: "CustomerProfileEditView",
    component: () =>
      import(
        /* webpackChunkName: "CustomerProfileEditView" */ "@/views/customer/CustomerProfileEditView.vue"
      ),
    meta: {
      layout: CustomerLayoutWithNav,
      access: [UserRoles.CUSTOMER],
    },
  },
  {
    path: "/customer/profile/change-password",
    name: "CustomerProfileChangePasswordView",
    component: () =>
      import(
        /* webpackChunkName: "CustomerProfileChangePasswordView" */ "@/views/customer/CustomerProfileChangePasswordView.vue"
      ),
    meta: {
      layout: CustomerLayoutWithNav,
      access: [UserRoles.CUSTOMER],
    },
  },
  {
    path: "/auth/registration/:id",
    name: "ConfirmRegistration",
    component: () =>
      import(
        /* webpackChunkName: "ConfirmRegistration" */ "@/views/ConfirmRegistrationView.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/auth/verify_customer/:id",
    name: "ConfirmRegistrationUnregisteredCustomer",
    component: () =>
      import(
        /* webpackChunkName: "ConfirmRegistration" */ "@/views/ConfirmRegistrationView.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/auth/registration-verify",
    name: "RegistrationVerifyView",
    component: () =>
      import(
        /* webpackChunkName: "RegistrationVerifyView" */ "@/views/RegistrationVerifyView.vue"
      ),
    meta: {
      layout: EmptyLayoutWithHeader,
    },
  },
  {
    path: "/subscriptions/unsubscribe/:id",
    name: "LeaveWaitlistView",
    component: () =>
      import(
        /* webpackChunkName: "LeaveWaitlistView" */ "@/views/LeaveWaitlistView.vue"
      ),
    meta: {
      layout: EmptyLayoutWithHeader,
    },
  },
  {
    path: "/auth/restore-password/:id",
    name: "RestorePasswordView",
    component: () =>
      import(
        /* webpackChunkName: "RestorePasswordView" */ "@/views/RestorePasswordView.vue"
      ),
    meta: {
      layout: EmptyLayoutWithHeader,
      typeAction: "reset",
    },
  },
  {
    path: "/auth/activation/:id",
    name: "ActivationPasswordView",
    component: () =>
      import(
        /* webpackChunkName: "RestorePasswordView" */ "@/views/RestorePasswordView.vue"
      ),
    meta: {
      layout: EmptyLayoutWithHeader,
      typeAction: "activate",
    },
  },
  {
    path: "/builder/estate/:estateId/lot/:lotId",
    name: "Builder",
    component: () =>
      import(
        /* webpackChunkName: "BuilderView" */ "@/views/builder/BuilderView.vue"
      ),
    meta: {
      layout: BuilderLayout,
    },
  },
  {
    path: "/builder/estate/:estateId/borrow-calculator/request-quote/:quoteId",
    name: "BuilderBorrowCalculator",
    component: () =>
      import(
        /* webpackChunkName: "BuilderBorrowCalculator" */ "@/views/builder/BuilderBorrowCalculator.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/builder/estate/:estateId/borrow-calculator/request-quote/:quoteId/house/:houseId/loan-amount/:loanAmount",
    name: "BuilderHouseBorrowCalculator",
    component: () =>
      import(
        /* webpackChunkName: "BuilderHouseBorrowCalculator" */ "@/views/builder/BuilderHouseBorrowCalculator.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    // DEPRECATED
    path: "/builder/request-quote/:quoteId/estate/:estateId",
    name: "RequestQuote",
    component: () =>
      import(
        /* webpackChunkName: "RequestQuoteView" */ "../views/builder/RequestQuoteView.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/contactUs",
    name: "AskAQuestion",
    component: () =>
      import(
        /* webpackChunkName: "AskAQuestion" */ "@/views/AskAQuestionView.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

export default routes;
