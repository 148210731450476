import { reactive } from "vue";
import { Module } from "vuex";
import businessApi from "@/services/api/businessApi";
import { AxiosResponse } from "axios";
import { Business } from "@/types/BusinessInterface";

const state = reactive({
  businesses: [] as Business[],
});

export type businessState = typeof state;

const businessStore: Module<businessState, never> = {
  namespaced: true,
  state,
  actions: {
    getAllBusiness(): Promise<AxiosResponse<Business[]>> {
      return businessApi.getAllBusiness();
    },
    getBusinessByLandownerId(): Promise<AxiosResponse<Business>> {
      return businessApi.getBusinessByLandownerId();
    },
  },
};

export default businessStore;
