import { getUser } from "@/features/useUser";
import { LotInterface } from "@/types/LotInterface";
import { UserRoles } from "@/enums/UserRoles";
import {
  LandownerDetailsInterface,
  SalesPersonDetailsInterface,
} from "@/types/UserInterface";

export const isLotOwner = (lot: LotInterface | null): boolean => {
  if (!lot) return true;
  if(!lot?.ownershipId) return true;

  const currentUser = getUser();
  const userDetails =
    currentUser.role === UserRoles.SALES_PERSON
      ? (currentUser.detailsId as SalesPersonDetailsInterface)
      : (currentUser.detailsId as LandownerDetailsInterface);

  const landownerId =
    currentUser.role === UserRoles.SALES_PERSON
      ? userDetails.landownerId
      : currentUser._id;

  return lot.ownershipId === landownerId;
};
