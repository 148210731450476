import { reactive } from "vue";
import { Module } from "vuex";
import { AxiosResponse } from "axios";
import eoiApi from "@/services/api/eoiApi";
import { BuyerState } from "@/enums/BuyerState";
import { EoiStatus } from "@/enums/EoiStatus";
import { EoiInterface } from "@/types/EoiInterface";
import { BuyerTypes } from "@/enums/BuyerTypes";
import { DocumentTypeEnum } from "@/enums/DocumentTypeEnum";
import { ContractFinanceStatus } from "@/enums/ContractFinanceStatus";

const eoiObjDefault = {
  buyerType: BuyerTypes.OWNER_OCCUPIER,
  status: EoiStatus.DRAFT,
  buyersData: {
    firstBuyer: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      state: BuyerState.VIC,
      address: "",
      documents: [
        {
          name: "",
          link: "",
          documentType: DocumentTypeEnum.DOCUMENTS,
          modifiedDate: "",
        },
      ],
    },
    secondBuyer: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      state: BuyerState.VIC,
      address: "",
      documents: [
        {
          name: "",
          link: "",
          documentType: DocumentTypeEnum.DOCUMENTS,
          modifiedDate: "",
        },
      ],
    },
  },
  conveyancerDetails: {
    firmName: "",
    address: "",
    contactPerson: {
      fullName: "",
      email: "",
      phone: "",
    },
  },
  contractConditions: {
    subjectToFinance: false,
    landLoanAmount: null,
    financeStatus: ContractFinanceStatus.PENDING,
    FIRB: false,
    otherConditions: "",
    bankApprovalDocument: {
      name: "",
      link: "",
      modifiedDate: "",
      documentType: DocumentTypeEnum.DOCUMENTS,
    },
  },
} as EoiInterface;

const state = reactive({
  eoiObj: eoiObjDefault,
  activeEoiCounter: 0,
  isLoggedOnDocusign: false,
  unregisteredCustomerId: ""
});

export type eoiState = typeof state;

const eoiStore: Module<eoiState, never> = {
  namespaced: true,

  state,

  mutations: {
    setEoiObj(state: eoiState, payload: EoiInterface): void {
      state.eoiObj = payload;
    },

    resetEoiObj(state: eoiState): void {
      state.eoiObj = eoiObjDefault;
    },

    setActiveEoiCounter(state: eoiState, payload: number): void {
      state.activeEoiCounter = payload;
    },
    setUnregisteredCustomerId(state: eoiState, payload: string): void {
      state.unregisteredCustomerId = payload;
    },
  },

  actions: {
    createEoi({ commit }, params = {}): Promise<AxiosResponse> {
      return eoiApi.createEoi(params).then((res) => {
        return res;
      });
    },

    submitEoi({ commit }, params = {}): Promise<AxiosResponse> {
      return eoiApi.submitEoi(params.id, params.data).then((res) => {
        commit("setEoiObj", res.data);
        return res;
      });
    },

    submitEoiForUnregisteredCustomer(
      { commit },
      params = {}
    ): Promise<AxiosResponse> {
      return eoiApi
        .submitEoiForUnregisteredCustomer(params.id, params.data)
        .then((res) => {
          commit("setEoiObj", res.data);
          commit("setUnregisteredCustomerId", res.data.customerId._id);
          return res;
        });
    },

    submitZeroDepositEoi({ commit }, params = {}): Promise<AxiosResponse> {
      return eoiApi.submitZeroDepositEoi(params.id, params.data).then((res) => {
        commit("setEoiObj", res.data);
        return res;
      });
    },

    submitZeroDepositEoiForUnregisteredCustomer(
      { commit },
      params = {}
    ): Promise<AxiosResponse> {
      return eoiApi
        .submitZeroDepositEoiForUnregisteredCustomer(params.id, params.data)
        .then((res) => {
          commit("setEoiObj", res.data);
          return res;
        });
    },

    getEoiById(_, params: string): Promise<AxiosResponse> {
      return eoiApi.getEoiById(params);
    },

    getEoiByIdAndSetToStore(
      { commit },
      params: string
    ): Promise<AxiosResponse> {
      return eoiApi.getEoiById(params).then((res) => {
        commit("setEoiObj", res.data);
        return res;
      });
    },

    uploadDocument(_, params = {}): Promise<AxiosResponse> {
      return eoiApi.uploadDocument(params.id, params.data);
    },

    deleteDocument(_, params = {}): Promise<AxiosResponse> {
      return eoiApi.deleteDocument(params);
    },

    uploadDocumentForUnregisteredCustomer(
      _,
      params = {}
    ): Promise<AxiosResponse> {
      return eoiApi.uploadDocumentForUnregisteredCustomer(
        params.id,
        params.data
      );
    },

    deleteDocumentForUnregisteredCustomer(
      _,
      params = {}
    ): Promise<AxiosResponse> {
      return eoiApi.deleteDocumentForUnregisteredCustomer(params);
    },

    downloadPdf(_, eoiId: string): Promise<AxiosResponse> {
      return eoiApi.downloadPdf(eoiId);
    },

    getEois(_, params = {}): Promise<AxiosResponse> {
      return eoiApi.getEois(params);
    },

    getEoi(_, id: string): Promise<AxiosResponse> {
      return eoiApi.getEoi(id);
    },

    getEoisGroupByLot(_, params = {}): Promise<AxiosResponse> {
      return eoiApi.getEoisGroupByLot(params);
    },

    getActiveEoisGroupByLot({ dispatch }, id: string) {
      return eoiApi
        .getEoisGroupByLot({
          estateId: id,
          status: [EoiStatus.PENDING, EoiStatus.WAITING_FOR_APPROVED],
        })
        .then((res) => {
          dispatch("getEoiCounter", id);
          return res;
        });
    },

    getEoisForLot(
      _,
      payload: { id: string; status: EoiStatus[]; estateId?: string }
    ): Promise<AxiosResponse> {
      return eoiApi.getEoisForLot(payload.id, payload.status, payload.estateId);
    },
    approveEoi(
      _,
      data: { eoiId: string; lotId: string }
    ): Promise<AxiosResponse> {
      return eoiApi.approveEoi(data.eoiId, data.lotId);
    },

    revokeEoi(
      _,
      data: { eoiId: string; lotId: string; reasonForTermination?: string }
    ): Promise<AxiosResponse> {
      return eoiApi.revokeEoi(
        data.eoiId,
        data.lotId,
        data?.reasonForTermination
      );
    },
    declineEoi(_, params = {}): Promise<AxiosResponse> {
      return eoiApi.declineEoi(params);
    },
    deleteEoi(_, eoiId: string): Promise<AxiosResponse> {
      return eoiApi.deleteEoi(eoiId);
    },
    saveDraftEoi({ commit }, params = {}): Promise<AxiosResponse> {
      return eoiApi.saveDraftEoi(params.id, params.data);
    },
    getEoisCustomerLots(_, customerId) {
      return eoiApi.getEoisCustomerLots(customerId);
    },
    updateEoiStatus(_, id) {
      return eoiApi.updateEoi(id, {
        status: EoiStatus.DECLINED,
      });
    },
    getEoiCounter({ commit }, id): Promise<AxiosResponse> {
      return eoiApi.eoiCounter(id).then((res) => {
        commit("setActiveEoiCounter", res.data);
        return res;
      });
    },
    setEoiStatusPending(_, id) {
      return eoiApi.updateEoi(id, {
        status: EoiStatus.PENDING,
      });
    },
    getEoiPaymentUrl(
      _,
      data: {
        eoiId: string;
        estateId: string;
        landownerId: string;
        lotId: string;
      }
    ) {
      return eoiApi.getEoiPaymentUrl(data);
    },
    getEoiPaymentUrlForUnregisteredCustomer(
      _,
      data: {
        eoiId: string;
        estateId: string;
        landownerId: string;
        lotId: string;
        customerId: string;
      }
    ) {
      return eoiApi.getEoiPaymentUrlForUnregisteredCustomer(data);
    },
    getCustomerLotList(_, params = {}): Promise<AxiosResponse> {
      return eoiApi.getCustomerLotList(params.id, params.data);
    },
    eoiRefundApprove(_, id: string): Promise<AxiosResponse> {
      return eoiApi.eoiRefundApprove(id);
    },
    eoiRefundDecline(_, params = {}): Promise<AxiosResponse> {
      return eoiApi.eoiRefundDecline(params.id, params.data);
    },
    getInvoice(_, params = {}) {
      return eoiApi.getInvoice(params);
    },
    getPaymentUrl(_, id: string): Promise<AxiosResponse> {
      return eoiApi.getPaymentUrl(id);
    },
    getDefaultTemplate() {
      return eoiApi.getDefaultTemplate();
    },
    getCustomTemplate(_, id: string) {
      return eoiApi.getCustomTemplate(id);
    },
    updateCustomTemplate(_, payload = {}) {
      return eoiApi.updateCustomTemplate(payload.templateId, payload.groups);
    },
    createCustomTemplate(_, payload = {}) {
      return eoiApi.createCustomTemplate(payload);
    },
    sendEmailWithAttachments(_, payload = {}): Promise<AxiosResponse> {
      return eoiApi.sendEmailWithAttachments(payload);
    },
    createUnregisteredCustomer(_, payload = {}): Promise<AxiosResponse> {
      return eoiApi.createUnregisteredCustomer(payload);
    },
  },
};

export default eoiStore;
