import store from "@/store";
import { POSITION, useToast } from "vue-toastification";
import { Ref, ref } from "vue";
import { AxiosResponse } from "axios";

const toast = useToast();

type RequestResult<T> = {
  success: boolean;
  data: T | null;
};

type UseRequestResponse = {
  error: Ref<boolean>;
  handleRequest: <T, P>(action: string, params: P) => Promise<RequestResult<T>>;
  handleRequestWithLoading: <T, P>(
    action: string,
    params: P,
    loading: Ref<boolean>
  ) => Promise<RequestResult<T>>;
};

export const useRequest = (
  errorMessage = "",
  successMessage = "",
  errorPosition?: POSITION
): UseRequestResponse => {
  const error: Ref<boolean> = ref(false);

  const handleRequest = async <T, P>(
    action: string,
    params: P
  ): Promise<RequestResult<T>> => {
    error.value = false;
    try {
      const response: AxiosResponse<T> = await store.dispatch(action, params);
      if (successMessage) toast.success(successMessage);
      return { success: true, data: response.data };
    } catch (err) {
      error.value = true;
      showErrorMessage(errorMessage, errorPosition);
      return { success: false, data: null };
    }
  };

  const handleRequestWithLoading = async <T, P>(
    action: string,
    params: P,
    loading: Ref<boolean>
  ): Promise<RequestResult<T>> => {
    loading.value = true;
    const res = await handleRequest<T, P>(action, params);
    loading.value = false;

    return res;
  };

  return {
    error,
    handleRequest,
    handleRequestWithLoading,
  };
};
const showErrorMessage = (message: string, position?: POSITION) =>
  toast.error(message || "Something went wrong", { position });
