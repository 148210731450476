import { EstateInterface } from "@/types/EstateInterface";
import { changePrimaryColor } from "../customer/changePrimaryColor";

export const changePrimaryColorEoiViews = (
  estate: EstateInterface,
  businessId: string | null
): void => {
  if (!businessId || !estate?.collaboratorBusiness) return;
  if (businessId !== estate?.collaboratorBusiness[0]?._id) {
    changePrimaryColor(estate.iconColor);
  }

  changePrimaryColor(estate.collaboratorBusiness[0]?.branding.color);
};
