import { AxiosResponse } from "axios";
import axiosClient from "@/services/api/index";
import { Business } from "@/types/BusinessInterface";

export default {
  getAllBusiness(): Promise<AxiosResponse<Business[]>> {
    return axiosClient.get("business");
  },
  getBusinessByLandownerId(): Promise<AxiosResponse<Business>> {
    return axiosClient.get("business/by-landowner-id");
  },
};
